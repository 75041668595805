<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="isInitialLoading">
  <app-loading-state></app-loading-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="!isInitialLoading && drives.length === 0">
  <app-empty-state [icon]="faSatellite" [text]="'No drives added yet'"></app-empty-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem" *ngIf="!isInitialLoading && drives.length === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div *ngIf="!isInitialLoading && drives.length > 0">
  <div>
  <div class="row">
    <div class="table-responsive mt-3">
      <table datatable [dtOptions]="dtOptions" class="table table-dark table-hover">
        <thead>
          <tr class="d-flex">
            <th class="col-2 pr-3">Plotter</th>
            <th class="col-2 pr-3">Drive (Dest.)</th>
            <th class="col-2 pr-3">Total</th>
            <th class="col-2 pr-3">Used</th>
            <th class="col-2 pr-3">Free</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let drive of drives | orderBy:'percent' :true; trackBy: trackBy" [plotter]="plotter" class="d-flex">
          <td class="col-2">{{drive.name}}
            <span class="dot ml-1"
              [attr.green]="lastUpdatedState(drive.lastUpdate, drive.satelliteUpdatedAt) === 0 ? true : null"
              [attr.orange]="lastUpdatedState(drive.lastUpdate, drive.satelliteUpdatedAt) === 1 ? true : null"
              [attr.red]="lastUpdatedState(drive.lastUpdate, drive.satelliteUpdatedAt) === 2 ? true : null">
            </span>
          </td>
          <td class="col-2">{{drive.letter}}</td>
          <td class="col-2">{{formatBytes(drive.total)}}TiB</td>
          <td class="col-2">{{formatBytes(drive.used)}}TiB ({{driveUsedPercent(drive.used, drive.total)}})%</td>
          <td class="col-2">{{formatBytes(driveFreeSpace(drive.used, drive.total))}}TiB ({{driveFreePercent(drive.used, drive.total)}})%
            <span class="dot"
              [attr.green]="driveFreeIndicator(drive.used, drive.total) === 0 ? true : null"
              [attr.orange]="driveFreeIndicator(drive.used, drive.total) === 1 ? true : null"
              [attr.red]="driveFreeIndicator(drive.used, drive.total) === 2 ? true : null">
            </span>
          </td>
      </tr>
      </tbody>
      </table>
      <div>(sort by clicking on the titles)</div>
    </div>
  </div>
</div>