<div class="row justify-content-center" style="font-size: 22px">
  Download the Satellite Software
</div>
<div class="row justify-content-center" style="margin-top: 1em">
  <a href="{{binaryDownloadUrl}}" target="_blank" style="text-decoration: none;">
    <div class="install-box download-button">
      <fa-icon [icon]="faDownload" class="fa" style="font-size: 18px"></fa-icon>&nbsp;&nbsp;Download the latest binary
    </div>
  </a>
</div>
<div class="row justify-content-center" style="margin-top: 1em">
  <div class="install-box">
    Install using npm:<br>
    <code>npm install --global chia-dashboard-satellite</code>
  </div>
</div>
