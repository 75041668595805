<div style="clear: both;"></div>
<footer style="padding-top: 1em">
  <div style="display: flex; justify-content: center; flex-wrap: wrap">

    <div style="display: flex;">
      <div style="padding-right: 1em; padding-left: 1em">
        <div style="justify-content: space-evenly">
          <span style="font-weight: 300; font-size: larger;">Help</span>
        </div>
        <div style="justify-content: space-evenly; padding-top: 20px;">
          <a href="https://docs.foxypool.io/chia-dashboard-satellite/" target="_blank" class="link highlight">
            Documentation
          </a>
          <br>
          <a href="https://discord.gg/pAwjCBssex" target="_blank" class="link highlight">
            Discord
          </a>
          <br>
          <a href="https://forum.chiadashboard.com" target="_blank" class="link highlight">
            Forum
          </a>
          <br>
        </div>
      </div>
    </div>
    <div style="display: flex;">
      <div style="padding-right: 1em; padding-left: 1em">
        <div style="justify-content: space-evenly">
          <span style="font-weight: 300; font-size: larger;">Links</span>
        
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="padding-right: 1em">
            <a href="https://chiacalculator.com" target="_blank" class="link highlight">
              Chia Calculator
            </a>
            <br>
            <a href="https://plot-plan.chia.foxypool.io" target="_blank" class="link highlight">
              Chia Plot Plan
            </a>
            <br>
            <a href="https://chiaexplorer.com" target="_blank" class="link highlight">
              Chia Explorer            </a>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;">
      <div style="padding-left: 1em">
        <div style="justify-content: space-evenly">
          <span style="font-weight: 300; font-size: larger;">Social</span>
        </div>
        <div style="justify-content: space-evenly; padding-top: 20px;">
          <a href="https://discord.gg/pAwjCBssex" target="_blank" class="link highlight">
            <fa-icon [icon]="faDiscord" class="fa highlight"></fa-icon>
          </a>
          <a href="https://github.com/thauch" target="_blank" style="padding-left: 10px">
            <fa-icon [icon]="faGithub" class="fa highlight"></fa-icon>
          </a>
          <a href="https://www.paypal.com/donate?business=VGRZESXG5Z4BQ&currency_code=USD" target="_blank" style="padding-left: 10px">
            <fa-icon [icon]="faPaypal" class="fa highlight"></fa-icon>
          </a>
        </div>
      </div>
    </div>

  </div>
  <div style="text-align: center; padding-bottom: 4px; padding-top: 1em">

<a href="https://www.paypal.com/donate?business=VGRZESXG5Z4BQ&currency_code=USD">Donate</a>
<hr>
Server Locations:<BR>
  <a href="https://us.chiadashboard.com">
    <img src="/assets/usa.png" width="25">
  </a>
  &nbsp;
  <a href="https://eu.chiadashboard.com">
    <img src="/assets/europe.png" width="25">
  </a>
<br><br>
Global Users: <span style="font-weight: 300; font-size: larger;">{{globalStats?.users}}</span> &nbsp;&nbsp; Global Satellites: <span style="font-weight: 300; font-size: larger;">{{globalStats?.satellites}}</span>
<hr>
<br>
    &copy; 2021 <a href="https://github.com/thauch" target="_blank">Giro</a> & <a href="https://github.com/NetManDE/chia-dashboard-ui/" target="_blank">NetMan</a>, based on the Sourcecode of <a href="https://github.com/felixbrucker/chia-dashboard-ui" target="_blank">Foxy</a><BR>
  </div>
</footer>