<nav class="navbar navbar-expand-xl navbar-dark sticky-navbar">
  <a class="navbar-brand" href="/">
    <img src="assets/chia_logo.svg" height="32" >
    <span style="margin-left: 10px; vertical-align: bottom;">Dashboard</span>
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleMenuCollapse()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" *ngIf="user">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-success" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onTabButtonClick()">
            Dashboard
          </button>
        </a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-success" id="plotterDropDown" ngbDropdownToggle>
              Plotters
            </button>
            <div ngbDropdownMenu aria-labelledby="plotterDropDown">
              <button ngbDropdownItem routerLink="/plotters/summary">Summary</button>
              <!-- <button ngbDropdownItem routerLink="/plotters/details">Details</button> -->
              <button ngbDropdownItem routerLink="/plotters/drives">Drives</button>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-success" routerLink="/harvesters/summary" routerLinkActive="active" (click)="onTabButtonClick()">
            Harvesters
          </button>
        </a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-success" routerLink="/farmers/summary" routerLinkActive="active" (click)="onTabButtonClick()">
            Farmers
          </button>
        </a>
      </li>
      <li class="nav-item"  *ngIf="user">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-info" (click)="openAddSatelliteModal()">
            + Add 
          </button>
        </a>
      </li>
    </ul>
    <div *ngIf="isAuthenticated" style="padding-right: 40px; padding-top: 8px; padding-bottom: 8px">
      <div class="currency-select-wrapper" *ngIf="(isAuthenticated || hasShareKey) && currencies.length > 0" style="margin-top: 10px;">
        <select class="custom-select currency-select" [ngModel]="selectedCurrency" (ngModelChange)="setSelectedCurrency($event)">
          <option *ngFor="let currency of currencies" [ngValue]="currency" class="currency-select-option">{{ currency.toUpperCase() }}</option>
        </select>
      </div>
      <div ngbDropdown class="d-inline-block" style="margin-left: 10px;">
        <button class="btn btn-outline-info" id="userDropdown" ngbDropdownToggle>
          <span *ngIf="user && user.avatarUrl">
            <img src="{{user.avatarUrl}}" width="22" height="22" style="border-radius: 100%">
            <span *ngIf="displayName() != null" style="margin-left: 8px">{{displayName()}}</span>
          </span>
        </button>
        <div ngbDropdownMenu aria-labelledby="userDropdown">
          <button ngbDropdownItem routerLink="/satellites" routerLinkActive="active" (click)="onTabButtonClick()">
            Satellites
          </button>
          <button ngbDropdownItem routerLink="account/dashboardorder" routerLinkActive="active">
            Account Settings
          </button>
          <button ngbDropdownItem routerLink="/account/sharedashboard" routerLinkActive="active">
            Share Dashboard
          </button>
          <button ngbDropdownItem (click)="logout()">
            Logout
          </button>

        </div>
      </div>
    </div>
    
    <div *ngIf="!isAuthenticated" style="padding-right: 8px; padding-top: 8px; padding-bottom: 8px">
      <button type="button" class="btn btn-dark" routerLink="/login" routerLinkActive="active" (click)="isMenuCollapsed = true">
        Login
      </button>
    </div>
  </div>
</nav>
<app-add-new-satellite-modal></app-add-new-satellite-modal>

